<template>
	<div class="send-command" :class="[source == 'list' ? 'batch' : 'one']">
		<el-button type="primary" v-if="source == 'list'" @click.native="initCommandList">批量指令下发</el-button>
		<el-button class="one-send" type="primary" @click="initCommandList" v-else>指令下发</el-button>
		<gc-dialog :show.sync="showDialog" :title="btnText" width="700px" @close="handleDialogClose">
			<div class="item-box">
				<span class="item-box-title" v-if="tenantType == 1 && source == 'list'">指令表单</span>
				<div class="item-box-content">
					<div class="meter-type-search" v-if="source == 'list'">
						<gc-custom-search
							keyWord="设备类型"
							width="100%"
							type="select"
							filterable
							:search.sync="batchDeviceTypeId"
							:searchOption="options.deviceTypeId"
						></gc-custom-search>
					</div>
					<div
						v-loading="loadingContent"
						element-loading-text="拼命加载中"
						element-loading-spinner="el-icon-loading"
						class="command-box"
					>
						<gc-empty v-if="commandParams.length == 0"></gc-empty>
						<div class="command-wrapper" v-else>
							<div class="command-sort row-block">
								<div class="title-pale">指令类型</div>
								<div
									class="menu-item"
									v-for="item in commandSortList"
									:key="item.cmdModelType"
									:class="{ active: item.cmdModelType == cmdModelType }"
									@click="handleCommandSortClick(item.cmdModelType)"
								>
									<span class="iconfont" :class="item.icon"></span>
									{{ item.cmdModelTypeName }}
								</div>
							</div>
							<div class="command-list row-block">
								<div class="title-pale">指令</div>
								<div
									class="menu-item"
									v-for="item in commandSortChildren"
									:key="item.cmdModelId"
									:class="{ active: item.cmdModelId == cmdModelId }"
									@click="handleCommandClick(item)"
								>
									{{ item.name }}
								</div>
							</div>
							<div class="command-content">
								<gc-empty v-if="!cmdModelId" pageType="directiveSend"></gc-empty>
								<command-form ref="commandFormParent" v-else :command-form="commandForm"></command-form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="item-box" v-if="tenantType == 1 && source == 'list'">
				<span class="item-box-title">其他参数</span>
				<div class="item-box-content other-params">
					<gc-custom-search
						key-word="客户类型"
						type="select"
						:search-option="options.userType"
						:search.sync="userType"
						:search-option-key="{ label: 'name', value: 'defaultValue' }"
						:selectClearable="true"
					></gc-custom-search>
					<gc-custom-search
						key-word="所属区域"
						type="tree-select"
						width="380px"
						:search.sync="waterRegionCode"
						:searchOption="treeOptions"
						:propsObj="{
							label: 'name',
							children: 'children',
							id: 'code',
							disabled: data => {
								return data.unSelectFlag && data.unSelectFlag === 1
							},
						}"
						:treeSelectClearable="true"
					></gc-custom-search>
				</div>
			</div>
			<div slot="footer" class="footer">
				<el-button @click="handleDialogClose">取 消</el-button>
				<el-button
					type="primary"
					:disabled="!cmdModelId || commandParams.length == 0"
					@click.native="confirmToSend"
					:loading="loadingBtn"
					:class="[loadingBtn ? 'loading-width' : '']"
				>
					提 交
				</el-button>
			</div>
		</gc-dialog>
	</div>
</template>

<script>
import CommandForm from './CommandForm.vue'
import { isBlank } from '@/utils/validate.js'
import { apiGetCommandList, apiBatchSendCommand, apiOneSendCommand } from '@/apis/batchDirectiveManage.api.js'
import { mapActions } from 'vuex'

export default {
	name: 'sendCommand',
	components: {
		CommandForm,
	},
	mixins: [],
	props: {
		btnText: {
			default: '批量指令下发',
		},
		source: {
			// list || detail
			default: 'list',
		},
		deviceTypeId: {}, //单个指令需传设备id
		deviceId: {}, //设备Id
		options: {}, //设备类型下拉
	},
	data() {
		return {
			showDialog: false,
			commandParams: [], //指令参数集
			commandSortList: [], //指令类型
			commandSortChildren: [], //指令
			cmdModelType: '', //当前选中的指令类型
			cmdModelId: '', //当前选中的指令
			batchDeviceTypeId: '', //设备类型-批量指令下发
			userType: '',
			waterRegionCode: '',
			commandForm: {}, //指令-表单
			loadingBtn: false, //按钮 loading
			loadingContent: false, //请求loading
		}
	},
	computed: {
		tenantType() {
			// 0-燃气租户 1-水务租户
			return this.$store.getters.userInfo.tenantType
		},
		treeOptions() {
			return this.$store.getters.userRegionTree
		},
	},
	watch: {
		// 请求可下发指令参数集
		batchDeviceTypeId(newVal) {
			if (!isBlank(newVal)) {
				// 数据重置
				this.commandParams = [] //指令参数集
				this.commandSortList = [] //指令类型
				this.commandSortChildren = [] //指令
				this.cmdModelType = '' //当前选中的指令类型
				this.cmdModelId = '' //当前选中的指令
				this.commandForm = {}
				this.$refs?.commandFormParent?.$refs?.commandForm.clearValidate()
				// 请求当前设备的参数集
				this.getCommandList(newVal)
			}
		},
	},
	created() {
		this.getTreeOptions()
	},
	methods: {
		...mapActions({
			judgeNeedPickOrg: 'user/judgeNeedPickOrg',
			getTreeOptions: 'user/getTreeOptions',
		}),
		initCommandList() {
			if (this.source == 'detail') {
				this.showDialog = true
				this.getCommandList(this.deviceTypeId)
			} else {
				this.judgeNeedPickOrg().then(res => {
					if (res) {
						this.showDialog = true
					}
				})
			}
		},
		handleDialogClose() {
			Object.assign(this.$data, this.$options.data())
			this.showDialog = false
		},
		getCommandList(id) {
			this.loadingContent = true
			let params = {
				isBatch: this.source === 'list' ? 1 : undefined,
			}
			if (this.tenantType == 1) {
				// 待补充 参数是否需要确认
				params['deviceTypeId'] = id
			} else {
				params['deviceTypeId'] = id
			}
			apiGetCommandList(params)
				.then((res = []) => {
					const iconArr = ['icon-read_order', 'icon-set_order', 'icon-valve_control_order']
					this.commandParams = res || []
					this.commandSortList = this.commandParams.map(item => {
						return {
							cmdModelType: item.cmdModelType,
							cmdModelTypeName: item.cmdModelTypeName,
							icon: iconArr[item.cmdModelType],
						}
					})
					this.cmdModelType = (this.commandSortList.length > 0 && this.commandSortList[0].cmdModelType) || ''
					this.commandSortChildren =
						(this.commandParams.find(item => item.cmdModelType == this.cmdModelType) || {}).cmdModelList ||
						[]
				})
				.finally(() => {
					this.loadingContent = false
				})
		},
		// 切换指令类型
		handleCommandSortClick(cmdModelType) {
			this.cmdModelType = cmdModelType
			this.cmdModelId = ''
			this.commandSortChildren =
				this.commandParams.find(item => item.cmdModelType == this.cmdModelType).cmdModelList || []
		},
		//切换指令
		handleCommandClick(item) {
			this.cmdModelId = item.cmdModelId
			this.commandForm = item.payload || {}
			if (this.commandForm.identifier === '05-remoteSecurityCheck') {
				this.$set(this.commandForm, 'remoteSecurityCheckRegular', '0')
				this.$set(this.commandForm, 'regularTime', this.dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'))
			}
			console.log(this.commandForm, 'this.commandForm')
		},
		// 确定下发
		confirmToSend() {
			this.$refs.commandFormParent.$refs.commandForm.validate(valid => {
				if (valid) {
					this.loadingBtn = true
					let params = {}
					const { remoteSecurityCheckRegular, regularTime, inputData = [], identifier } = this.commandForm
					let cmdInfo = {
						remoteSecurityCheckRegular,
						regularTime,
					}
					// 设置定时上传参数（参数必须是十次，如果页面不展示，则默认为0000）
					if (identifier === '05-uploadDaysAndTimes') {
						inputData.forEach(item => {
							if (item.uiType.display && isBlank(item.dataType.defaultValue)) {
								cmdInfo[item.identifier] = '0000'
							} else {
								cmdInfo[item.identifier] = item.dataType.defaultValue
							}
						})
					} else {
						inputData.forEach(item => {
							cmdInfo[item.identifier] = item.dataType.defaultValue
						})
					}
					// 部分固定不显示的参数为空也需要回填，取消参数去空处理
					params.cmdInfo = cmdInfo
					params.waterRegionCode = this.waterRegionCode || undefined
					let apiSend = null
					let msg = '批量指令下发成功'
					// 批量指令下发
					if (this.source == 'list') {
						params.deviceTypeId = this.batchDeviceTypeId
						params.cmdModelType = +this.cmdModelType
						params.cmdModelId = this.cmdModelId
						params.cmdModelName =
							(this.commandSortChildren.find(item => item.cmdModelId == this.cmdModelId) || {}).name || ''
						if (this.tenantType == 1) {
							params['userType'] = this.userType
							params['waterRegionCode'] = this.waterRegionCode
						}
						apiSend = apiBatchSendCommand
					} else {
						// 单个指令下发
						params.deviceId = this.deviceId
						params.cmdModelId = this.cmdModelId
						apiSend = apiOneSendCommand
						msg = '指令下发成功'
					}
					apiSend(params)
						.then(() => {
							this.$message.success(msg)
							this.showDialog = false
							if (this.source === 'list') {
								this.$emit('update-list')
							}
							//指令下发 更新设备规则配置列表
							if (this.source === 'detail') {
								this.$store.commit('account/SET_CONFIG_RULE_UPDATE', true)
							}
						})
						.finally(() => {
							this.loadingBtn = false
						})
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.batch {
	@include base-button($width: 120px);
}
.one {
	@include base-button($width: 100px);
	.one-send {
		margin-right: 20px;
	}
}
::v-deep {
	.el-dialog__body {
		padding: 0 0 15px 0 !important;
	}
	.gc-blank-page {
		margin-top: 102px !important;
	}
}
.meter-type-search {
	padding: 0 24px;
}
.command-box {
	min-height: 260px;
}
.command-wrapper {
	box-sizing: border-box;
	padding-top: 20px;
	height: 363px;
	display: flex;
	.row-block {
		height: 100%;
		border-right: 1px dashed #c4cde6;
		overflow-y: auto;
		.title-pale {
			padding: 10px 0 20px 24px;
			font-size: 12px;
			color: #999999;
			line-height: 18px;
		}
		.menu-item {
			padding-left: 23px;
			line-height: 34px;
			background: white;
			font-size: 14px;
			font-weight: 400;
			color: #4a4a4a;
			transition: all ease-in-out 0.2s;
			cursor: pointer;
			&.active {
				color: $base-color-primary;
				background: #e9f2fe;
			}
		}
	}
	.command-sort {
		flex: 0 0 140px;
	}
	.command-list {
		flex: 0 0 219px;
	}
	.command-content {
		flex: 1;
		height: 100%;
		width: 339px;
	}
}
.footer {
	@include base-button($height: 32px, $radius: 16px);
	.loading-width {
		width: 100px !important;
	}
}
.item-box {
	.item-box-title {
		width: 100%;
		display: inline-block;
		padding: 0 24px;
		margin-bottom: 12px;
		color: #ababab;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			left: 90px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			border-bottom: 1px solid #eef0f3;
		}
	}
	.other-params {
		padding: 0 24px;
		.gc-custom-search {
			width: 100% !important;
			& + .gc-custom-search {
				margin-top: 12px;
			}
		}
	}
	.item-box-content {
		padding-bottom: 12px;
	}
}
</style>
